import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import ErrorPage from './ErrorPage.vue'

@Component({
  preFetch ({ ssrContext }) {
    if (process.env.SERVER) {
      // @ts-ignore
      ssrContext.res.status(404)
    }
  },
  components: {
    ErrorPage
  }
})
export default class HttpError404 extends BaseMixin {
}
