import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import WebsiteHeader from '../../components/WebsiteHeader.vue'
import BaseLayoutMixin from '../../mixins/baseLayoutMixin'
import IconBooksproutLeaf from '../../../../components/svg-icons/IconBooksproutLeaf.vue'

@Component({
  components: {
    WebsiteHeader,
    IconBooksproutLeaf
  }
})
export default class ErrorPage extends BaseLayoutMixin {
  @Prop({ type: String }) public readonly errorCode!: string
  @Prop({ type: String }) readonly message!: string
  @Prop({ type: String }) readonly subText!: string
}
